import '../carparc.scss';
import React from 'react';

import Container from 'components/layout/Container';
import Section from 'components/layout/Section';

import Button from 'components/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import ignitionImg from '../../../../assets/images/ignition.png';

export default function Ignition() {
    return (
        <>
            <Container theme="light" className="reverseMobile paddingY6" autoMinHeight={false}>
                <Section>
                    <div className="product">
                        <h2 className="productHeader">Ignition</h2>
                        <p className="ignitionText">
                            Ignition by Blue Flag is a powerful mapping and data visualisation platform built specifically to help automotive brands analyse their Units in Operation (UIO) in direct
                            relation to their dealer network. Purpose-built in-house, it transforms raw data into strategic insights — making it easier to understand where your vehicles are on the
                            road and how effectively your network is supporting them.
                        </p>

                        <p className="ignitionText">
                            With your UIO data preloaded and layered alongside key datasets like existing dealerships, competitor sites, service centres, and road infrastructure, Ignition helps you
                            identify network gaps, assess PMA coverage, and optimise dealer placements. Intuitive and customisable, it gives you a clear view of your market presence and ensures your
                            network strategy is backed by data, not guesswork.
                        </p>

                        <Button to="https://blueflag.com.au/contact-us/" type="new-outline" color="primary">
                            Learn More <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                </Section>

                <Section className="forecastingImage">
                    <img src={ignitionImg} alt="Ignition" width="100%" className="ignitionImage" />
                </Section>
            </Container>
        </>
    );
}
