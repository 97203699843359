import '../carparc.scss';
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/Button';

import Container from 'components/layout/Container';
import Section from 'components/layout/Section';

const dataFields = [
    { vin: 'JTEBR3FJ00XXXXX01', make: 'Toyota', model: 'LandCrusier Prado', state: 'VIC', postcode: '3205', suburb: 'South Melbourne', reg_status: 'Registered', reg_expiry_date: '2025-02-19' },
    { vin: 'JTEBR3FJ00XXXXX02', make: 'Toyota', model: 'LandCrusier Prado', state: 'VIC', postcode: '3121', suburb: 'Cremorne', reg_status: 'Unregistered', reg_expiry_date: '2024-12-09' },
    { vin: 'JTEBR3FJ00XXXXX03', make: 'Toyota', model: 'LandCrusier Prado', state: 'VIC', postcode: '3047', suburb: 'Broadmeadows', reg_status: 'Registered', reg_expiry_date: '2025-04-23' },
    { vin: 'JTEBR3FJ00XXXXX04', make: 'Toyota', model: 'LandCrusier Prado', state: 'NSW', postcode: '2015', suburb: 'Eveleigh', reg_status: 'Registered', reg_expiry_date: '2025-08-12' },
    { vin: 'JTEBR3FJ00XXXXX05', make: 'Toyota', model: 'LandCrusier Prado', state: 'SA', postcode: '5171', suburb: 'McLaren Vale', reg_status: 'Unregistered', reg_expiry_date: '2017-07-28' },
    { vin: 'JTEBR3FJ00XXXXX06', make: 'Toyota', model: 'LandCrusier Prado', state: 'WA', postcode: '6007', suburb: 'West Leederville', reg_status: 'Unregistered', reg_expiry_date: '2022-09-03' },
];

export default function VINDecoderDatabase({ nevdisDataSources }) {
    return (
        <>
            <Container theme="light" className="paddingY5" autoMinHeight={false}>
                <Section className="database">
                    <div className="">
                        <table>
                            <thead>
                                <tr>
                                    <th>VIN</th>
                                    <th>MAKE</th>
                                    <th>MODEL</th>
                                    <th>STATE</th>
                                    <th>POSTCODE</th>
                                    <th>SUBURB</th>
                                    <th>REGISTRATION STATUS</th>
                                    <th>REGISTRATION EXPIRY DATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataFields.map((ff, ii) => (
                                    <tr key={ii}>
                                        <td>{ff.vin}</td>
                                        <td>{ff.make}</td>
                                        <td>{ff.model}</td>
                                        <td>{ff.state}</td>
                                        <td>{ff.postcode}</td>
                                        <td>{ff.suburb}</td>
                                        <td>{ff.reg_status}</td>
                                        <td>{ff.reg_expiry_date}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="listCarParc">
                            <p className="listCopy">
                                <ul>
                                    <li>Identify where vehicles are located down to a postcode and suburb in real-time.</li>
                                    <li>Optimise supply chain management by identifying the location of units in operation and reducing lead times for delivery.</li>
                                    <li>Develop aftersales parts and service budgets based on accurate data.</li>
                                    <li>Increased sales by targeting marketing efforts effectively.</li>
                                    <li>Tracking units in operation can enable car companies to manage their warranty programs better by identifying patterns in vehicle defects or malfunctions.</li>
                                    <li>Better identify the age of your vehicles (UIO).</li>
                                    <li>Processed data returned in CSV.</li>
                                </ul>
                            </p>

                            <Button to="https://blueflag.com.au/contact-us/" type="new-outline" color="primary">
                                Learn More <FontAwesomeIcon icon={faArrowRight} />
                            </Button>
                        </div>
                    </div>
                </Section>
            </Container>
        </>
    );
}
